/*!

=========================================================
* Now UI Kit PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-pro-react
* Copyright 2021 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "./assets/css/bootstrap.min.css";
import "./assets/scss/now-ui-kit.scss?v=1.5.0";
import "./assets/demo/demo.css?v=1.5.0";
import "./assets/demo/react-demo.css?v=1.5.0";
import "./assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages
// import AboutUs from "./views/examples/AboutUs.js";
// import BlogPost from "./views/examples/BlogPost.js";
// import BlogPosts from "views/examples/BlogPosts.js";
// import ContactUs from "views/examples/ContactUs.js";
// import Ecommerce from "views/examples/Ecommerce.js";
// import Index from "views/Index.js";
// import LandingPage from "views/examples/LandingPage.js";
// import LoginPage from "views/examples/LoginPage.js";
// import NucleoIcons from "views/NucleoIcons.js";
// import Presentation from "views/Presentation.js";
import Portal from "./views/landing-page-portal/landing-page.js";
import ENTOCSV from "./views/employee-navigator/en.csv.js";
// import Pricing from "views/examples/Pricing.js";
// import ProductPage from "views/examples/ProductPage.js";
// import ProfilePage from "views/examples/ProfilePage.js";
// import Sections from "views/Sections.js";
// import SignupPage from "views/examples/SignupPage.js";
// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
        <Route exact path="/" render={(props) => <Portal {...props} />} />
        <Route exact path="/employee-navigator" render={(props) => <ENTOCSV {...props} />} />
        {/* <Route exact path="/sign-in" render={(props) => <Login {...props} />} /> */}
        <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
