import React from "react";
import {
    Form,
    FormGroup,
    Input,
    FormText,
    Button
  } from "reactstrap";

  // react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
import Navbar from "components/Navbars/Navbar.js";
import FileUpload from '../../components/CustomUpload/FileUpload.js';
import RingLoader from "react-spinners/RingLoader";
import { Alert } from 'reactstrap';
import { API_END_POINTS } from '../../endpoint'
// core components
// import ScrollTransparentNavbar from "components/Navbars/Navbar.js";
import "./en.csv.css";

class ENTOCSV extends React.Component {

    constructor(){
        super();
        this.state = {
            file : null,
            fileName: "",
            addDependentMembers: false,
            addRecordsToSalesforce:false,
            emailAddress:"",
            loading: false,
            alertVisible: false,
            alertColor: "success",
            message: "",

        }
        this.onDismiss = this.onDismiss.bind(this);
    }

    onDismiss(){
        this.setState({alertVisible: !this.state.alertVisible});
    }

    handleFileChange = (file) => {
        this.setState({fileName : file.name, file : file})
    }

    handleFileRemove = () =>{
        this.setState({fileName : "", file : null})
    }

    handleSubmit = () =>{
       
        if (this.state.file == null) {
            this.setState({alertColor:"danger", alertVisible:true, message:"Select a media file to upload"})
            return;
        }

        this.setState({loading : true})

        var form = new FormData();
        form.append('data', new Blob([JSON.stringify({
            method: "convert-en-file-to-csv", 
            "body" : {
                addDependentMembers: this.state.addDependentMembers,
                addRecordsToSalesforce: this.state.addRecordsToSalesforce,
                email: this.state.emailAddress
            }
        })], {type: 'application/json'}));
        form.append('file', this.state.file);

        var xhr = new XMLHttpRequest();
        xhr.open('post', `${API_END_POINTS.GOOGLE_CLOUD_FUNCTION_API_ENDPOINT}`);
        xhr.responseType = 'json';
        xhr.onload = () => {
            let response = xhr.response;
            this.setState({loading : false})
            if(response.Ok){
                this.setState({loading : false, fileName : "", file : null, addDependentMembers:false, addRecordsToSalesforce:false, emailAddress:"", message:"File has been sent/uploaded successfully", alertVisible:true, alertColor:"success"})
            } else {
                this.setState({alertColor:"danger", alertVisible:true, message:response.Data})
            }
            console.log(xhr.response);
            
        };
        xhr.send(form);
    }

    handleOnChange = (e) => {
        let change = {}
        if (e.target !== undefined){
            change[e.target.name] = e.target.value
        } else {
            change[e.props.name] = e.state.value
        }
        
        this.setState(change)
    }

    componentDidMount(){
        
    }

    render() {
        return(
            <div className="App">
                <Alert color={this.state.alertColor} isOpen={this.state.alertVisible}>
                    <div className="container">
                        <div className="alert-icon">
                        {/* <i className="now-ui-icons ui-2_like"></i> */}
                        </div>
                        <strong>{this.state.message}</strong> 
                        <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={this.onDismiss}
                        >
                        <span aria-hidden="true">
                            <i className="now-ui-icons ui-1_simple-remove"></i>
                        </span>
                        </button>
                    </div>
                </Alert>
                <Navbar />
                    <div className="info info-hover">
                        <div className="icon icon-primary">
                            <i className="now-ui-icons travel_info"></i>
                        </div>
                        <h4 className="info-title">AllyHealth Portal</h4>
                        <p className="description">
                            This portal is currently designed to convert Employee Navigator (XML) File to csv format
                        </p>
                    </div>
                    <main class="container">
                        <div class="bd-example">
                            <Form enctype="multipart/form-data">
                                <FormGroup>
                                    <label htmlFor="exampleInputEmail1">Email address</label>
                                    <Input
                                        aria-describedby="emailHelp"
                                        id="exampleInputEmail1"
                                        placeholder="Enter email"
                                        type="email"
                                        name="emailAddress"
                                        onChange={this.handleOnChange}
                                        value={this.state.emailAddress}
                                    ></Input>
                                    <FormText className="text-muted" color="default" id="emailHelp">
                                        Please provide your email address on which you want your file to be received.
                                    </FormText>
                                </FormGroup>
                                <FormGroup>
                                    <div class="d-flex justify-content-between">
                                        <div>Add Dependent Members to the File?</div>
                                        <div>
                                            <Switch name="addDependentMembers" defaultValue={false} value={this.state.addDependentMembers} onChange={this.handleOnChange}/>
                                        </div>
                                    </div>
                                </FormGroup>
                                {/* <FormGroup>
                                    <div class="d-flex justify-content-between">
                                        <div>Add Records to Salesforce?</div>
                                        <div>
                                            <Switch name="addRecordsToSalesforce" defaultValue={false} value={this.state.addRecordsToSalesforce} onChange={this.handleOnChange}/>
                                        </div>
                                    </div>
                                </FormGroup> */}
                                <FormGroup>
                                <div class="d-flex justify-content-between">
                                    <div className="fileName">{this.state.fileName}</div>
                                    <FileUpload filePresent={this.state.file !== null} onFileChange={this.handleFileChange} onFileRemove={this.handleFileRemove} onChange={this.handleOnChange}/>
                                </div>
                                </FormGroup>
                                <Button color="primary" type="button" onClick={this.handleSubmit}>
                                    Submit
                                </Button>
                            </Form>
                        </div>
                            <div className={ this.state.loading ? 'spinner-container block-display' : 'spinner-container' } >
                                <div className="spinner-item">
                                    <RingLoader color="#f96332" />
                                </div>
                            </div>
                    </main>
            </div>
            
        )
    }
}

export default ENTOCSV;
