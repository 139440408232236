import React from "react";

import { Button } from "reactstrap";

function FileUpload(props) {
  const fileInput = React.createRef();
  const handleFileChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      props.onFileChange(file);  
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  // const handleSubmit = e => {
  // e.preventDefault();
  // this.state.file is the file/image uploaded
  // in this function you can save the image (this.state.file) on form submit
  // you have to call it yourself
  // };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    fileInput.current.value = null;
    props.onFileRemove(); 
  };
  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleFileChange} ref={fileInput} />
      <div>
        {props.filePresent === false ? (
          <Button className="btn-round" color="info" onClick={handleClick}>
            Select File
          </Button>
        ) : (
          <span>
            <Button className="btn-round" color="info" onClick={handleClick}>
              Change
            </Button>
            &nbsp;
            <Button color="danger" className="btn-round" onClick={handleRemove}>
              <i className="fa fa-times" /> Remove
            </Button>
          </span>
        )}
      </div>
    </div>
  );
}

export default FileUpload;
