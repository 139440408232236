export const API_END_POINTS = {
    GOOGLE_CLOUD_FUNCTION_BASE_URL : 'https://us-central1-profound-iris-188113.cloudfunctions.net',
    GOOGLE_CLOUD_FUNCTION_API_ENDPOINT : 'https://ion.felixis.com/api/v1/request',
    LOCALHOST_REACT : 'http://localhost:3000',
    // GOOGLE_CLOUD_FUNCTION_API_ENDPOINT : 'http://localhost:4000/api/v1/request',
    TEST_SALESFORCE_CONNECTION : '/test-salesforce-connection',
    GET_ACCESS_TOKEN : '/get-access-token',
    SALESFORCE_OAUTH_CALLBACK : '/salesforce-oauth-callback',
    // OAUTH_REDIRECT_URI: 'https://us-central1-felixis-ion.cloudfunctions.net/salesforce-oauth-callback',
    OAUTH_REDIRECT_URI: 'http://localhost:5000/salesforce-oauth-callback',
    PRODUCTION_AUTHORIZE_URL: 'https://login.salesforce.com/services/oauth2/authorize',
    SANDBOX_AUTHORIZE__URL: 'https://test.salesforce.com/services/oauth2/authorize'
}