import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

function ScrollTransparentNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [navbarColor, setNavbarColor] = React.useState( "" );
  const [buyButtonColor, setBuyButtonColor] = React.useState("info");
  React.useEffect(() => {
    // const updateNavbarColor = () => {
    //   if (
    //     document.documentElement.scrollTop > 499 ||
    //     document.body.scrollTop > 499
    //   ) {
    //     setNavbarColor("");
    //     setBuyButtonColor("info");
    //   } else if (
    //     document.documentElement.scrollTop < 500 ||
    //     document.body.scrollTop < 500
    //   ) {
    //     setNavbarColor(" navbar-transparent");
    //     setBuyButtonColor("neutral");
    //   }
    // };
    // window.addEventListener("scroll", updateNavbarColor);
    // return function cleanup() {
    //   window.removeEventListener("scroll", updateNavbarColor);
    // };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <div class="navbar">
      <Navbar className={"fixed-top" + navbarColor} color="white" expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
              Home
            </NavbarBrand>
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto" id="ceva" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#pablo"
                  id="navbarDropdownMenuLink1"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="now-ui-icons design_app"></i>
                  <p>Convert to CSV</p>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink1" right>
                  <DropdownItem to="/" tag={Link}>
                    {/* <i className="now-ui-icons design_image"></i> */}
                    834 File
                  </DropdownItem>
                  <DropdownItem to="/employee-navigator" tag={Link}>
                    {/* <i className="now-ui-icons business_chart-pie-36"></i> */}
                    Employee Navigator File
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
                <NavItem>
                <Button
                  className="nav-link btn-default"
                  color={buyButtonColor}
                  href=""
                  target="_blank"
                >
                  <p>Log Out</p>
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      </div>
    </>
  );
}

export default ScrollTransparentNavbar;
